import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['content'];

  open() {
    this.contentTarget.classList.remove('hide');
  }

  close() {
    this.contentTarget.classList.add('hide');
  }
}
